/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Motor from './Motor';
import YesNo from './YesNo';

/**
 * The MachineFileFixedMotor model module.
 * @module model/MachineFileFixedMotor
 * @version 0.0.1
 */
class MachineFileFixedMotor {
    /**
     * Constructs a new <code>MachineFileFixedMotor</code>.
     * @alias module:model/MachineFileFixedMotor
     */
    constructor() { 
        
        MachineFileFixedMotor.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MachineFileFixedMotor</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MachineFileFixedMotor} obj Optional instance to populate.
     * @return {module:model/MachineFileFixedMotor} The populated <code>MachineFileFixedMotor</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MachineFileFixedMotor();

            if (data.hasOwnProperty('motorFrequency')) {
                obj['motorFrequency'] = ApiClient.convertToType(data['motorFrequency'], 'String');
            }
            if (data.hasOwnProperty('expectedSpeed')) {
                obj['expectedSpeed'] = ApiClient.convertToType(data['expectedSpeed'], 'Number');
            }
            if (data.hasOwnProperty('rotationDirection')) {
                obj['rotationDirection'] = ApiClient.convertToType(data['rotationDirection'], 'String');
            }
            if (data.hasOwnProperty('vsdvfdInstalled')) {
                obj['vsdvfdInstalled'] = YesNo.constructFromObject(data['vsdvfdInstalled']);
            }
            if (data.hasOwnProperty('electronicBrakeInstalled')) {
                obj['electronicBrakeInstalled'] = YesNo.constructFromObject(data['electronicBrakeInstalled']);
            }
            if (data.hasOwnProperty('motorSheaveDiameterAsSpecified')) {
                obj['motorSheaveDiameterAsSpecified'] = YesNo.constructFromObject(data['motorSheaveDiameterAsSpecified']);
            }
            if (data.hasOwnProperty('drivenSheaveDiameterAsSpecified')) {
                obj['drivenSheaveDiameterAsSpecified'] = YesNo.constructFromObject(data['drivenSheaveDiameterAsSpecified']);
            }
            if (data.hasOwnProperty('motorA')) {
                obj['motorA'] = Motor.constructFromObject(data['motorA']);
            }
            if (data.hasOwnProperty('motorB')) {
                obj['motorB'] = Motor.constructFromObject(data['motorB']);
            }
            if (data.hasOwnProperty('exciterSerials')) {
                obj['exciterSerials'] = ApiClient.convertToType(data['exciterSerials'], ['String']);
            }
            if (data.hasOwnProperty('exciterLocations')) {
                obj['exciterLocations'] = ApiClient.convertToType(data['exciterLocations'], ['String']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MachineFileFixedMotor</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MachineFileFixedMotor</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['motorFrequency'] && !(typeof data['motorFrequency'] === 'string' || data['motorFrequency'] instanceof String)) {
            throw new Error("Expected the field `motorFrequency` to be a primitive type in the JSON string but got " + data['motorFrequency']);
        }
        // ensure the json data is a string
        if (data['rotationDirection'] && !(typeof data['rotationDirection'] === 'string' || data['rotationDirection'] instanceof String)) {
            throw new Error("Expected the field `rotationDirection` to be a primitive type in the JSON string but got " + data['rotationDirection']);
        }
        // validate the optional field `motorA`
        if (data['motorA']) { // data not null
          Motor.validateJSON(data['motorA']);
        }
        // validate the optional field `motorB`
        if (data['motorB']) { // data not null
          Motor.validateJSON(data['motorB']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['exciterSerials'])) {
            throw new Error("Expected the field `exciterSerials` to be an array in the JSON data but got " + data['exciterSerials']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['exciterLocations'])) {
            throw new Error("Expected the field `exciterLocations` to be an array in the JSON data but got " + data['exciterLocations']);
        }

        return true;
    }


}



/**
 * @member {String} motorFrequency
 */
MachineFileFixedMotor.prototype['motorFrequency'] = undefined;

/**
 * @member {Number} expectedSpeed
 */
MachineFileFixedMotor.prototype['expectedSpeed'] = undefined;

/**
 * @member {module:model/MachineFileFixedMotor.RotationDirectionEnum} rotationDirection
 */
MachineFileFixedMotor.prototype['rotationDirection'] = undefined;

/**
 * @member {module:model/YesNo} vsdvfdInstalled
 */
MachineFileFixedMotor.prototype['vsdvfdInstalled'] = undefined;

/**
 * @member {module:model/YesNo} electronicBrakeInstalled
 */
MachineFileFixedMotor.prototype['electronicBrakeInstalled'] = undefined;

/**
 * @member {module:model/YesNo} motorSheaveDiameterAsSpecified
 */
MachineFileFixedMotor.prototype['motorSheaveDiameterAsSpecified'] = undefined;

/**
 * @member {module:model/YesNo} drivenSheaveDiameterAsSpecified
 */
MachineFileFixedMotor.prototype['drivenSheaveDiameterAsSpecified'] = undefined;

/**
 * @member {module:model/Motor} motorA
 */
MachineFileFixedMotor.prototype['motorA'] = undefined;

/**
 * @member {module:model/Motor} motorB
 */
MachineFileFixedMotor.prototype['motorB'] = undefined;

/**
 * @member {Array.<String>} exciterSerials
 */
MachineFileFixedMotor.prototype['exciterSerials'] = undefined;

/**
 * @member {Array.<String>} exciterLocations
 */
MachineFileFixedMotor.prototype['exciterLocations'] = undefined;





/**
 * Allowed values for the <code>rotationDirection</code> property.
 * @enum {String}
 * @readonly
 */
MachineFileFixedMotor['RotationDirectionEnum'] = {

    /**
     * value: "With flow"
     * @const
     */
    "WITH_FLOW": "With flow",

    /**
     * value: "Counter-flow"
     * @const
     */
    "COUNTER_FLOW": "Counter-flow"
};



export default MachineFileFixedMotor;

