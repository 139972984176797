<template>
  <div>
    <h1 class="alert-heading">Authentication Error</h1>
    <b-alert show variant="danger">
      {{error_message}}
    </b-alert>
    <b-link to="/auth" variant="primary">
      Try again
    </b-link>
  </div>
</template>

<script>
export default {
  name: 'AuthError',
  computed: {
    error_message () {
      return this.$route.query.error_message
    }
  }
}
</script>
