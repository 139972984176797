<template>
  <div>
    <h1 class="page-header">Screen {{ machineSerial.toUpperCase() }}</h1>
    <div v-if="machine" class="row">
      <div class="col-md-4">
        <machine-info :machine="machine" @baseFileEdit="baseFileEdit"></machine-info>
      </div>
      <div class="col-md-8">
        <machine-life-cycle :machine="machine" @machineUpdated="fetchMachine"></machine-life-cycle>
      </div>
    </div>
    <div v-else class="text-center">
      <span v-if="errorText">{{ errorText }}</span>
      <b-spinner v-else label="Loading..." show variant="secondary"></b-spinner>
    </div>
  </div>
</template>

<script>
import machineInfo from '@/components/MachineInfo.vue'
import machineLifeCycle from '@/components/MachineLifeCycle.vue'
import { machinesApi } from '@/backend'

export default {
  name: 'MachinePage',
  components: {
    machineInfo,
    machineLifeCycle
  },
  data () {
    return {
      machine: null,
      errorText: null
    }
  },
  mounted () {
    this.fetchMachine()
  },
  computed: {
    machineSerial: function () {
      return this.$route.params.serial
    }
  },
  watch: {
    $route: {
      handler: function (to) {
        if (to.name === 'machine' && this.$route.params.serial !== undefined) {
          this.fetchMachine()
        }
      }
    }
  },
  methods: {
    fetchMachine: function () {
      machinesApi.getMachine(this.machineSerial, (error, data) => {
        if (error) {
          this.errorText = 'Failed fetching machine data'
          console.error('Failed fetching machine because: ' + error)
          return
        }
        this.machine = data
        this.errorText = null
      })
    },
    baseFileEdit: function (newSerial) {
      this.$router.push({ name: 'machine', params: { serial: newSerial } })
      this.fetchMachine()
    }
  }
}
</script>

<style lang="scss">
</style>