<template>
  <div>
    <b-navbar class="mb-3" toggleable="lg" type="light">
      <b-navbar-brand>
        <img alt="Logo" class="navbar-mo-logo ml-lg-0 d-none d-sm-inline-block" src="/images/metso-logo.svg">
        <img alt="Logo" class="navbar-mo-logo ml-lg-0 d-inline-block d-sm-none" src="/images/mo-logo-shorthand.svg">
        <span class="ml-1 navbar-app-text">
          ScreenCheck Portal
        </span>
      </b-navbar-brand>

      <b-navbar-toggle v-if="authValid" target="nav-collapse"></b-navbar-toggle>

      <b-collapse v-if="authValid" id="nav-collapse" is-nav>
        <b-navbar-nav>
          <div class="header-divider mx-3 mt-2 d-none d-lg-block"></div>
          <b-nav-item exact exact-active-class="active" to="/reports">
            <font-awesome-icon class="mr-1" icon="list-alt"/>
            My Reports
          </b-nav-item>
          <b-nav-item exact active-class="active" to="/devices">
            <font-awesome-icon class="mr-1" icon="mobile-alt"/>
            My Devices
          </b-nav-item>
          <b-nav-item active-class="active" to="/machines" v-if="isUserViewAll">
            <font-awesome-icon class="mr-1" icon="barcode"/>
            All Screens
          </b-nav-item>
          <b-nav-item exact exact-active-class="active" to="/reports/all" v-if="isUserViewAll">
            <font-awesome-icon class="mr-1" icon="list-alt"/>
            All Reports
          </b-nav-item>
          <b-nav-item-dropdown v-if="isUserAdmin">
            <template #button-content>
              <font-awesome-icon class="mr-1" icon="users-cog"/>
              Admin
            </template>
            <b-dropdown-item active-class="active" to="/users">
              <font-awesome-icon icon="user"/>
              Users
            </b-dropdown-item>
            <b-dropdown-item active-class="active" to="/devices/all">
              <font-awesome-icon icon="mobile-alt"/>
              All Devices
            </b-dropdown-item>
            <b-dropdown-item active-class="active" to="/tokens">
              <font-awesome-icon icon="link"/>
              Token Requests
            </b-dropdown-item>
            <b-dropdown-item active-class="active" to="/log">
              <font-awesome-icon icon="align-left"/>
              Log
            </b-dropdown-item>
            <b-dropdown-item active-class="active" to="/admin/stats">
              <font-awesome-icon icon="tachometer-alt"/>
              Stats
            </b-dropdown-item>
            <b-dropdown-item active-class="active" to="/admin/device-logs">
              <font-awesome-icon icon="mobile-alt"/>
              Device Logs
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item exact exact-active-class="active" v-if="isUserViewAll && isUserEngineeringManager">
            <open-reviews-notification/>
          </b-nav-item>
          <b-nav-item-dropdown right>
            <template #button-content>
              <font-awesome-icon class="mr-1" icon="user"/>
            </template>
            <b-dropdown-header><span :title="userLongid">{{ userLabel }}</span></b-dropdown-header>
            <b-dropdown-item href="/auth/logout">
              <font-awesome-icon class="mr-1" icon="sign-out-alt"/>
              Logout
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item href="#" @click="showUpdateAlert">
              <font-awesome-icon class="mr-1" icon="download"/>
              Install ScreenCheck App
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-header>Privacy Notice</b-dropdown-header>
            <b-dropdown-item href="https://metso.sharepoint.com/sites/Legalandcompliance/SitePages/Privacy-Notices.aspx">For Metso Employees
            </b-dropdown-item>
            <b-dropdown-item href="https://www.metso.com/legal-and-privacy/privacy-notice/">Metso’s Privacy Notice</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-text><small class="text-muted">{{versionFile.version}}</small></b-dropdown-text>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-container>
      <b-alert v-model="updateAlertVisible" dismissible variant="primary">
        <p>
          <font-awesome-icon class="mr-2" icon="download"/>
          <strong>ScreenCheck App is now available on Google Play!</strong>
        </p>
        <p>
          To install the latest version of ScreenCheck App please click the Google Play button below.
        </p>
        <div class="text-center">
          <a href="https://play.google.com/store/apps/details?id=com.jcbachmann.mogroup.screencheck" target="_blank">
            <img alt="Google Play" class="google-play" src="/images/google-play-badge.png">
          </a>
        </div>
      </b-alert>
      <keep-alive>
        <router-view/>
      </keep-alive>
    </b-container>
  </div>
</template>

<script>
import './app.scss'
import { mapGetters } from 'vuex'
import versionFile from '@/utils/version.json'
import OpenReviewsNotification from '@/components/machine_file/OpenReviewsNotification.vue'

export default {
  name: 'app',
  components: { OpenReviewsNotification },
  data: function () {
    return {
      updateAlertVisible: false,
      versionFile,
    }
  },
  computed: {
    ...mapGetters(['authValid', 'isUserViewAll', 'isUserAdmin', 'userLongid', 'userLabel', 'isUserEngineeringManager']),
  },
  methods: {
    showUpdateAlert () {
      this.updateAlertVisible = true
    },
  }
}
</script>

<style lang="scss" scoped>
.google-play {
  height: 80px;
}
</style>
