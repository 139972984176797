<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <span>Static Data</span>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="float-left">
          <font-awesome-icon icon="barcode"/>
          S/N
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.machineSerialNumber" style="text-transform:uppercase" trim
                        @input="serialInput" @keypress="serialKeyPress"></b-form-input>
          <span v-else>{{ machineFile.static.machineSerialNumber }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          <font-awesome-icon icon="tags"/>
          Machine type
        </div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.static.machineType" @input="emitChange" list="machineTypeListId"></b-form-input>
            <datalist id="machineTypeListId">
              <option v-for="type in machineTypeList" v-bind:key="type">{{ type }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.static.machineType }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Dealer name
        </div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.static.dealerName" @input="emitChange" list="dealerListId"></b-form-input>
            <datalist id="dealerListId">
              <option v-for="dealer in dealerList" v-bind:key="dealer">{{ dealer }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.static.dealerName }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Customer equipment code
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.customerEquipmentCode" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.customerEquipmentCode }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          <font-awesome-icon icon="briefcase"/>
          Customer Name
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.customerName" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.customerName }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          <font-awesome-icon icon="location"/>
          Site Name
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.siteName" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.static.siteName }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Application
        </div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.static.application" @input="emitChange" list="applicationListId"></b-form-input>
            <datalist id="applicationListId">
              <option v-for="application in applicationList" v-bind:key="application">{{ application }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.static.application }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Number of decks
        </div>
        <div class="float-right">
          <b-form-group v-if="editable" class="mb-0">
            <b-form-select v-model="machineFile.static.decksAmount" :options="Object.values(MachineFileStatic.DecksAmountEnum)">
              <template #first>
                <b-form-select-option :value="null"></b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <span v-else>{{ machineFile.static.decksAmount }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Drive type
        </div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input v-if="editable" class="text-right" v-model="machineFile.static.driveType" @input="emitChange" list="driveTypeListId"></b-form-input>
            <datalist id="driveTypeListId">
              <option v-for="type in driveTypeList" v-bind:key="type">{{ type }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.static.driveType }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Drive position
        </div>
        <div class="float-right">
          <b-form-group v-if="editable" class="mb-0">
            <b-form-select v-model="machineFile.static.drivePosition" :options="MachineFileStatic.DrivePositionEnum">
              <template #first>
                <b-form-select-option :value="null"></b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <span v-else>{{ machineFile.static.drivePosition }}</span>
        </div>
      </li>
    </ul>
    <div v-if="shouldShowDriveTypeExtension">
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <div class="float-left">
            Number of shaft lines
          </div>
          <div class="float-right">
            <b-form-group v-if="editable" class="mb-0">
              <b-form-select v-model="machineFile.static.shaftLinesAmount" :options="Object.values(this.MachineFileStatic.ShaftLinesAmountEnum)">
                <template #first>
                  <b-form-select-option :value="null"></b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <span v-else>{{ machineFile.static.shaftLinesAmount }}</span>
          </div>
        </li>
        <li class="list-group-item">
          <div class="float-left">
            Number of exciters
          </div>
          <div class="float-right">
            <b-form-group v-if="editable" class="mb-0">
              <b-form-select v-model="machineFile.static.excitersAmount" :options="Object.values(MachineFileStatic.ExcitersAmountEnum)">
                <template #first>
                  <b-form-select-option :value="null"></b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <span v-else>{{ machineFile.static.excitersAmount }}</span>
          </div>
        </li>
        <li class="list-group-item">
          <div class="float-left">
            Mechanism type
          </div>
          <div class="float-right">
            <div v-if="editable">
              <b-form-input class="text-right" v-model="machineFile.static.mechanismType" @input="emitChange" list="mechanismTypeListId"></b-form-input>
              <datalist id="mechanismTypeListId">
                <option v-for="type in mechanismTypeList" v-bind:key="type">{{ type }}</option>
              </datalist>
            </div>
            <span v-else>{{ machineFile.static.mechanismType }}</span>
          </div>
        </li>
        <li class="list-group-item">
          <div class="float-left" v-if="shouldShowExciterType1">
            Exciter type
          </div>
          <div class="float-left" v-else>
            Exciter type - Feed side
          </div>
          <div class="float-right">
            <div v-if="editable">
              <b-form-input class="text-right" v-model="machineFile.static.exciterTypes[0]" @input="emitChange" list="exciter0TypeListId"></b-form-input>
              <datalist id="exciter0TypeListId">
                <option v-for="type in exciterTypeList" v-bind:key="type">{{ type }}</option>
              </datalist>
            </div>
            <span v-else>{{ machineFile.static.exciterTypes[0] }}</span>
          </div>
        </li>
        <li class="list-group-item" v-if="shouldShowExciterType2">
          <div class="float-left" v-if="shouldShowExciterType3">
            Exciter type - Middle
          </div>
          <div class="float-left" v-else>
            Exciter type - Discharge side
          </div>
          <div class="float-right">
            <div v-if="editable">
              <b-form-input class="text-right" v-model="machineFile.static.exciterTypes[1]" @input="emitChange" list="exciter1TypeListId"></b-form-input>
              <datalist id="exciter1TypeListId">
                <option v-for="type in exciterTypeList" v-bind:key="type">{{ type }}</option>
              </datalist>
            </div>
            <span v-else>{{ machineFile.static.exciterTypes[1] }}</span>
          </div>
        </li>
        <li class="list-group-item" v-if="shouldShowExciterType3">
          <div class="float-left">
            Exciter type - Discharge side
          </div>
          <div class="float-right">
            <div v-if="editable">
              <b-form-input class="text-right" v-model="machineFile.static.exciterTypes[2]" @input="emitChange" list="exciter2TypeListId"></b-form-input>
              <datalist id="exciter2TypeListId">
                <option v-for="type in exciterTypeList" v-bind:key="type">{{ type }}</option>
              </datalist>
            </div>
            <span v-else>{{ machineFile.static.exciterTypes[2] }}</span>
          </div>
        </li>
      </ul>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="float-left">
          Lubrication by
        </div>
        <div class="float-right">
          <b-form-group v-if="editable" class="mb-0">
            <b-form-select v-model="machineFile.static.lubrication" :options="MachineFileStatic.LubricationEnum">
              <template #first>
                <b-form-select-option :value="null"></b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <span v-else>{{ machineFile.static.lubrication }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Motion type
        </div>
        <div class="float-right">
          <b-form-group v-if="editable" class="mb-0">
            <b-form-select v-model="machineFile.static.motionType" :options="MachineFileStatic.MotionTypeEnum">
              <template #first>
                <b-form-select-option :value="null"></b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <span v-else>{{ machineFile.static.motionType }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { MachineFileStatic } from '@/screencheck_portal_api/src'

export default {
  name: 'StaticData',
  props: {
    data: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      machineFile: { ...this.data }
    }
  },
  methods: {
    emitChange () {
      this.$emit('onChange', this.machineFile)
    },
    serialInput: function () {
      if (this.modalSerial.match(/[^a-zA-Z0-9 .-]/g)) {
        this.modalSerial = this.modalSerial.toUpperCase().replace(/[^A-Z0-9 .-]/g, '')
      }
      this.emitChange()
    },
    serialKeyPress: function (ev) {
      if (String.fromCharCode(ev.which).match(/[^a-zA-Z0-9 .-]/g) && event.keyCode !== 46 && event.keyCode !== 8) {
        ev.preventDefault()
      }
    },
  },
  components: {},
  computed: {
    MachineFileStatic () {
      return MachineFileStatic
    },
    shouldShowExciterType1 () {
      return this.machineFile.static.shaftLinesAmount === 1 || this.machineFile.static.shaftLinesAmount === null
    },
    shouldShowExciterType2 () {
      return this.machineFile.static.shaftLinesAmount > 1 && this.machineFile.static.driveType?.toLowerCase() !== 'unbalanced motors'
    },
    shouldShowExciterType3 () {
      return this.machineFile.static.shaftLinesAmount > 2 && this.machineFile.static.driveType?.toLowerCase() !== 'unbalanced motors'
    },
    shouldShowDriveTypeExtension () {
      return this.machineFile.static.driveType?.toLowerCase() !== 'unbalanced motors'
    },
    applicationList () {
      return [
        'Minerals processing',
        'Aggregate',
        'Mining'
      ]
    },
    driveTypeList () {
      return [
        'Direct Drive',
        'Indirect Drive (Pulleys/Belt/Cardan Shaft)',
        'Unbalanced Motors'
      ]
    },
    mechanismTypeList () {
      return [
        'ML',
        'ME',
        'MV',
        'MB',
        'V',
        'B',
        'BLz / Unbalanced Motor'
      ]
    },
    machineTypeList () {
      return [
        'ACVL / High Frequency screen',
        'BSE',
        'CBS',
        'CVB',
        'DF',
        'DS',
        'EF',
        'ES',
        'FS',
        'LH',
        'MF',
        'Mining Feeder',
        'PF',
        'RF',
        'TK',
        'TS',
        'UFS',
        'VF',
        'VG',
        'other (aggregate)',
        'other (mining)',
      ]
    },
    dealerList () {
      return [
        'Ascendum Maquinas',
        'BH RUDA',
        'BIA Group',
        'Cleveland Brothers',
        'Compass Equipment',
        'CPOS',
        'Crisp Industries',
        'David Blench',
        'DQMS',
        'DUO Group',
        'Ferreyros S.A.',
        'Frantoparts',
        'Galadari GTHE',
        'Great West Equipment',
        'Haein Corporation',
        'McCloskey International',
        'MCES',
        'McHale',
        'Mellot',
        'Miller Machinery',
        'Mimico NZ',
        'N/A',
        'Nordmet Hellas',
        'Pilot Crushtec',
        'Quinn CAT',
        'Red Seal Industries',
        'Righini',
        'Rocky Mountain Equipment',
        'Shaorui Heavy Industries',
        'SITSA',
        'TESAB',
        'TMEC',
        'Toromont Industries Ltd.',
        'Tuff Byrant',
        'Uawithya',
        'Värnamo Kross Konsult AB',
        'VPc',
        'Wagner',
        'Westate',
      ]
    },
    exciterTypeList () {
      switch (this.machineFile.static.mechanismType) {
        case 'ML':
          return ['80', '100']
        case 'ME':
          return ['100']
        case 'MV':
          return ['1', '2', '3', '4']
        case 'MB':
          return ['40', '55', '85']
        case 'V':
          return ['100', '120', '140', '150', '160', '175', '180']
        case 'B':
          return ['10', 'X10', 'L10', 'LX10', '13', 'X13', 'L13', '16', 'XS16', 'X16', 'F16', 'FF16', '20', 'X20']
        case 'BLz / Unbalanced Motor':
          return []
        default:
          return []
      }
    }
  }
}
</script>

<style lang="scss">
</style>
