/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The OpenReviewsNotification model module.
 * @module model/OpenReviewsNotification
 * @version 0.0.1
 */
class OpenReviewsNotification {
    /**
     * Constructs a new <code>OpenReviewsNotification</code>.
     * @alias module:model/OpenReviewsNotification
     */
    constructor() { 
        
        OpenReviewsNotification.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>OpenReviewsNotification</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OpenReviewsNotification} obj Optional instance to populate.
     * @return {module:model/OpenReviewsNotification} The populated <code>OpenReviewsNotification</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OpenReviewsNotification();

            if (data.hasOwnProperty('machineSerial')) {
                obj['machineSerial'] = ApiClient.convertToType(data['machineSerial'], 'String');
            }
            if (data.hasOwnProperty('machineType')) {
                obj['machineType'] = ApiClient.convertToType(data['machineType'], 'String');
            }
            if (data.hasOwnProperty('customerName')) {
                obj['customerName'] = ApiClient.convertToType(data['customerName'], 'String');
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
            if (data.hasOwnProperty('author')) {
                obj['author'] = ApiClient.convertToType(data['author'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>OpenReviewsNotification</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>OpenReviewsNotification</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['machineSerial'] && !(typeof data['machineSerial'] === 'string' || data['machineSerial'] instanceof String)) {
            throw new Error("Expected the field `machineSerial` to be a primitive type in the JSON string but got " + data['machineSerial']);
        }
        // ensure the json data is a string
        if (data['machineType'] && !(typeof data['machineType'] === 'string' || data['machineType'] instanceof String)) {
            throw new Error("Expected the field `machineType` to be a primitive type in the JSON string but got " + data['machineType']);
        }
        // ensure the json data is a string
        if (data['customerName'] && !(typeof data['customerName'] === 'string' || data['customerName'] instanceof String)) {
            throw new Error("Expected the field `customerName` to be a primitive type in the JSON string but got " + data['customerName']);
        }
        // ensure the json data is a string
        if (data['author'] && !(typeof data['author'] === 'string' || data['author'] instanceof String)) {
            throw new Error("Expected the field `author` to be a primitive type in the JSON string but got " + data['author']);
        }

        return true;
    }


}



/**
 * @member {String} machineSerial
 */
OpenReviewsNotification.prototype['machineSerial'] = undefined;

/**
 * @member {String} machineType
 */
OpenReviewsNotification.prototype['machineType'] = undefined;

/**
 * @member {String} customerName
 */
OpenReviewsNotification.prototype['customerName'] = undefined;

/**
 * @member {Date} timestamp
 */
OpenReviewsNotification.prototype['timestamp'] = undefined;

/**
 * @member {String} author
 */
OpenReviewsNotification.prototype['author'] = undefined;






export default OpenReviewsNotification;

