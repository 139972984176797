<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <span>Suspension</span>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="float-left">Isolation frame present?</div>
        <div class="float-right">
          <b-form-group v-if="editable" class="mb-0">
            <b-form-select v-model="machineFile.fixed.isolationFramePresent" :options="MachineFileFixed.IsolationFramePresentEnum">
              <template #first>
                <b-form-select-option :value="null"></b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <span v-else>{{ machineFile.fixed.isolationFramePresent }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">Screen suspension type</div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.fixed.screenSuspensionType" @input="emitChange" list="screenTypeListId"></b-form-input>
            <datalist id="screenTypeListId">
              <option v-for="type in screenTypeList" v-bind:key="type">{{ type }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.fixed.screenSuspensionType }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="card m-auto">
          <div class="card-header d-flex align-items-center justify-content-between">
            <span>Screen Suspension</span>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="float-left">Feed left inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.feedLeftInner"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.feedLeftInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Feed left outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.feedLeftOuter"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.feedLeftOuter }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Feed right inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.feedRightInner"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.feedRightInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Feed right outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.feedRightOuter"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.feedRightOuter }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge left inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.dischargeLeftInner"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.dischargeLeftInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge left outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.dischargeLeftOuter"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.dischargeLeftOuter }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge right inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.dischargeRightInner"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.dischargeRightInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge right outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.screenSuspensionFeedDischarge.dischargeRightOuter"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.screenSuspensionFeedDischarge.dischargeRightOuter }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Screen sideways suspension type</div>
              <div class="float-right">
                <div v-if="editable">
                  <b-form-input class="text-right" v-model="machineFile.fixed.screenSidewaysSuspensionType" @input="emitChange"
                                list="screenSidewaysTypeListId"></b-form-input>
                  <datalist id="screenSidewaysTypeListId">
                    <option v-for="type in screenSidewaysTypeList" v-bind:key="type">{{ type }}</option>
                  </datalist>
                </div>
                <span v-else>{{ machineFile.fixed.screenSidewaysSuspensionType }}</span>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowIsolationFrame">
        <div class="card">
          <div class="card-header d-flex align-items-center justify-content-between">
            <span>Isolation Frame</span>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="float-left">Mounted on suspensions?</div>
              <div class="float-right">
                <b-form-group @change="emitChange" v-if="editable">
                  <b-form-radio-group
                      id="radio-group-isolationFrameMountedOnSuspensions"
                      v-model="machineFile.fixed.isolationFrameMountedOnSuspensions"
                      :options="radioOptions"
                      name="radio-group-isolationFrameMountedOnSuspensions"
                  ></b-form-radio-group>
                </b-form-group>
                <span v-else>{{ machineFile.fixed.isolationFrameMountedOnSuspensions }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Isolation frame suspension type</div>
              <div class="float-right">
                <div v-if="editable">
                  <b-form-input class="text-right" v-model="machineFile.fixed.isolationFrameSuspensionType" @input="emitChange"
                                list="isolationFrameTypeListId"></b-form-input>
                  <datalist id="isolationFrameTypeListId">
                    <option v-for="type in isolationFrameTypeList" v-bind:key="type">{{ type }}</option>
                  </datalist>
                </div>
                <span v-else>{{ machineFile.fixed.isolationFrameSuspensionType }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Feed left inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.feedLeftInner"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.feedLeftInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Feed left outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.feedLeftOuter"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.feedLeftOuter }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Feed right inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.feedRightInner"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.feedRightInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Feed right outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.feedRightOuter"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.feedRightOuter }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge left inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.dischargeLeftInner"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.dischargeLeftInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge left outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.dischargeLeftOuter"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.dischargeLeftOuter }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge right inner quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.dischargeRightInner"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.dischargeRightInner }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Discharge right outer quantity</div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.isolationFrameFeedDischarge.dischargeRightOuter"
                              @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.isolationFrameFeedDischarge.dischargeRightOuter }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">Isolation frame suspension quantity, type and size as specified in the GA drawing?</div>
              <div class="float-right">
                <b-form-group @change="emitChange" v-if="editable">
                  <b-form-radio-group
                      id="radio-group-isolationFrameCorrectlySpecified"
                      v-model="machineFile.fixed.isolationFrameCorrectlySpecified"
                      :options="radioOptions"
                      name="radio-group-isolationFrameCorrectlySpecified"
                  ></b-form-radio-group>
                </b-form-group>
                <span v-else>{{ machineFile.fixed.isolationFrameCorrectlySpecified }}</span>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

import { MachineFileFixed } from '@/screencheck_portal_api/src'

export default {
  name: 'SuspensionData',
  props: {
    data: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      machineFile: { ...this.data },
      radioOptions: [
        { text: 'yes', value: 'YES' },
        { text: 'no', value: 'NO' },
      ],
    }
  },
  methods: {
    emitChange () {
      this.$emit('onChange', this.machineFile)
    }
  },
  components: {},
  computed: {
    MachineFileFixed () {
      return MachineFileFixed
    },
    shouldShowIsolationFrame () {
      return this.machineFile.fixed.isolationFramePresent === 'Yes, installed'
    },
    screenTypeList () {
      return [
        'Rubber encapsulated steel coil spring',
        'Steel coil spring vertical',
        'Steel coil spring horizontal and vertical',
        'Rubber spring',
        'Air spring',
      ]
    },
    screenSidewaysTypeList () {
      return [
        'Friction check',
        'Rubber stop horizontal',
        'Steel coil spring',
        'None'
      ]
    },
    isolationFrameTypeList () {
      return [
        'Rubber encapsulated steel coil spring',
        'Steel coil spring vertical',
        'Rubber block',
        'Rubber spring',
        'Air spring',
      ]
    },
  }
}
</script>

<style lang="scss">
</style>
