/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FeedDischarge from './FeedDischarge';
import MachineFileFixedMedia from './MachineFileFixedMedia';
import MachineFileFixedMotor from './MachineFileFixedMotor';
import MachineFileFixedProcess from './MachineFileFixedProcess';
import YesNo from './YesNo';

/**
 * The MachineFileFixed model module.
 * @module model/MachineFileFixed
 * @version 0.0.1
 */
class MachineFileFixed {
    /**
     * Constructs a new <code>MachineFileFixed</code>.
     * @alias module:model/MachineFileFixed
     */
    constructor() { 
        
        MachineFileFixed.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MachineFileFixed</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MachineFileFixed} obj Optional instance to populate.
     * @return {module:model/MachineFileFixed} The populated <code>MachineFileFixed</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MachineFileFixed();

            if (data.hasOwnProperty('weight')) {
                obj['weight'] = ApiClient.convertToType(data['weight'], 'Number');
            }
            if (data.hasOwnProperty('weightMethod')) {
                obj['weightMethod'] = ApiClient.convertToType(data['weightMethod'], 'String');
            }
            if (data.hasOwnProperty('process')) {
                obj['process'] = MachineFileFixedProcess.constructFromObject(data['process']);
            }
            if (data.hasOwnProperty('counterweights')) {
                obj['counterweights'] = ApiClient.convertToType(data['counterweights'], ['String']);
            }
            if (data.hasOwnProperty('media')) {
                obj['media'] = MachineFileFixedMedia.constructFromObject(data['media']);
            }
            if (data.hasOwnProperty('isolationFramePresent')) {
                obj['isolationFramePresent'] = ApiClient.convertToType(data['isolationFramePresent'], 'String');
            }
            if (data.hasOwnProperty('screenSuspensionType')) {
                obj['screenSuspensionType'] = ApiClient.convertToType(data['screenSuspensionType'], 'String');
            }
            if (data.hasOwnProperty('screenSuspensionFeedDischarge')) {
                obj['screenSuspensionFeedDischarge'] = FeedDischarge.constructFromObject(data['screenSuspensionFeedDischarge']);
            }
            if (data.hasOwnProperty('screenSidewaysSuspensionType')) {
                obj['screenSidewaysSuspensionType'] = ApiClient.convertToType(data['screenSidewaysSuspensionType'], 'String');
            }
            if (data.hasOwnProperty('isolationFrameMountedOnSuspensions')) {
                obj['isolationFrameMountedOnSuspensions'] = YesNo.constructFromObject(data['isolationFrameMountedOnSuspensions']);
            }
            if (data.hasOwnProperty('isolationFrameCorrectlySpecified')) {
                obj['isolationFrameCorrectlySpecified'] = YesNo.constructFromObject(data['isolationFrameCorrectlySpecified']);
            }
            if (data.hasOwnProperty('isolationFrameSuspensionType')) {
                obj['isolationFrameSuspensionType'] = ApiClient.convertToType(data['isolationFrameSuspensionType'], 'String');
            }
            if (data.hasOwnProperty('isolationFrameFeedDischarge')) {
                obj['isolationFrameFeedDischarge'] = FeedDischarge.constructFromObject(data['isolationFrameFeedDischarge']);
            }
            if (data.hasOwnProperty('lubricationSpec')) {
                obj['lubricationSpec'] = ApiClient.convertToType(data['lubricationSpec'], 'String');
            }
            if (data.hasOwnProperty('greasingPowerUnit')) {
                obj['greasingPowerUnit'] = ApiClient.convertToType(data['greasingPowerUnit'], 'String');
            }
            if (data.hasOwnProperty('motor')) {
                obj['motor'] = MachineFileFixedMotor.constructFromObject(data['motor']);
            }
            if (data.hasOwnProperty('gaDrawingNumberRevision')) {
                obj['gaDrawingNumberRevision'] = ApiClient.convertToType(data['gaDrawingNumberRevision'], 'String');
            }
            if (data.hasOwnProperty('instructionManualNumberRevision')) {
                obj['instructionManualNumberRevision'] = ApiClient.convertToType(data['instructionManualNumberRevision'], 'String');
            }
            if (data.hasOwnProperty('sparePartBookNumberRevision')) {
                obj['sparePartBookNumberRevision'] = ApiClient.convertToType(data['sparePartBookNumberRevision'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MachineFileFixed</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MachineFileFixed</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['weightMethod'] && !(typeof data['weightMethod'] === 'string' || data['weightMethod'] instanceof String)) {
            throw new Error("Expected the field `weightMethod` to be a primitive type in the JSON string but got " + data['weightMethod']);
        }
        // validate the optional field `process`
        if (data['process']) { // data not null
          MachineFileFixedProcess.validateJSON(data['process']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['counterweights'])) {
            throw new Error("Expected the field `counterweights` to be an array in the JSON data but got " + data['counterweights']);
        }
        // validate the optional field `media`
        if (data['media']) { // data not null
          MachineFileFixedMedia.validateJSON(data['media']);
        }
        // ensure the json data is a string
        if (data['isolationFramePresent'] && !(typeof data['isolationFramePresent'] === 'string' || data['isolationFramePresent'] instanceof String)) {
            throw new Error("Expected the field `isolationFramePresent` to be a primitive type in the JSON string but got " + data['isolationFramePresent']);
        }
        // ensure the json data is a string
        if (data['screenSuspensionType'] && !(typeof data['screenSuspensionType'] === 'string' || data['screenSuspensionType'] instanceof String)) {
            throw new Error("Expected the field `screenSuspensionType` to be a primitive type in the JSON string but got " + data['screenSuspensionType']);
        }
        // validate the optional field `screenSuspensionFeedDischarge`
        if (data['screenSuspensionFeedDischarge']) { // data not null
          FeedDischarge.validateJSON(data['screenSuspensionFeedDischarge']);
        }
        // ensure the json data is a string
        if (data['screenSidewaysSuspensionType'] && !(typeof data['screenSidewaysSuspensionType'] === 'string' || data['screenSidewaysSuspensionType'] instanceof String)) {
            throw new Error("Expected the field `screenSidewaysSuspensionType` to be a primitive type in the JSON string but got " + data['screenSidewaysSuspensionType']);
        }
        // ensure the json data is a string
        if (data['isolationFrameSuspensionType'] && !(typeof data['isolationFrameSuspensionType'] === 'string' || data['isolationFrameSuspensionType'] instanceof String)) {
            throw new Error("Expected the field `isolationFrameSuspensionType` to be a primitive type in the JSON string but got " + data['isolationFrameSuspensionType']);
        }
        // validate the optional field `isolationFrameFeedDischarge`
        if (data['isolationFrameFeedDischarge']) { // data not null
          FeedDischarge.validateJSON(data['isolationFrameFeedDischarge']);
        }
        // ensure the json data is a string
        if (data['lubricationSpec'] && !(typeof data['lubricationSpec'] === 'string' || data['lubricationSpec'] instanceof String)) {
            throw new Error("Expected the field `lubricationSpec` to be a primitive type in the JSON string but got " + data['lubricationSpec']);
        }
        // ensure the json data is a string
        if (data['greasingPowerUnit'] && !(typeof data['greasingPowerUnit'] === 'string' || data['greasingPowerUnit'] instanceof String)) {
            throw new Error("Expected the field `greasingPowerUnit` to be a primitive type in the JSON string but got " + data['greasingPowerUnit']);
        }
        // validate the optional field `motor`
        if (data['motor']) { // data not null
          MachineFileFixedMotor.validateJSON(data['motor']);
        }
        // ensure the json data is a string
        if (data['gaDrawingNumberRevision'] && !(typeof data['gaDrawingNumberRevision'] === 'string' || data['gaDrawingNumberRevision'] instanceof String)) {
            throw new Error("Expected the field `gaDrawingNumberRevision` to be a primitive type in the JSON string but got " + data['gaDrawingNumberRevision']);
        }
        // ensure the json data is a string
        if (data['instructionManualNumberRevision'] && !(typeof data['instructionManualNumberRevision'] === 'string' || data['instructionManualNumberRevision'] instanceof String)) {
            throw new Error("Expected the field `instructionManualNumberRevision` to be a primitive type in the JSON string but got " + data['instructionManualNumberRevision']);
        }
        // ensure the json data is a string
        if (data['sparePartBookNumberRevision'] && !(typeof data['sparePartBookNumberRevision'] === 'string' || data['sparePartBookNumberRevision'] instanceof String)) {
            throw new Error("Expected the field `sparePartBookNumberRevision` to be a primitive type in the JSON string but got " + data['sparePartBookNumberRevision']);
        }

        return true;
    }


}



/**
 * @member {Number} weight
 */
MachineFileFixed.prototype['weight'] = undefined;

/**
 * @member {String} weightMethod
 */
MachineFileFixed.prototype['weightMethod'] = undefined;

/**
 * @member {module:model/MachineFileFixedProcess} process
 */
MachineFileFixed.prototype['process'] = undefined;

/**
 * @member {Array.<String>} counterweights
 */
MachineFileFixed.prototype['counterweights'] = undefined;

/**
 * @member {module:model/MachineFileFixedMedia} media
 */
MachineFileFixed.prototype['media'] = undefined;

/**
 * @member {module:model/MachineFileFixed.IsolationFramePresentEnum} isolationFramePresent
 */
MachineFileFixed.prototype['isolationFramePresent'] = undefined;

/**
 * @member {String} screenSuspensionType
 */
MachineFileFixed.prototype['screenSuspensionType'] = undefined;

/**
 * @member {module:model/FeedDischarge} screenSuspensionFeedDischarge
 */
MachineFileFixed.prototype['screenSuspensionFeedDischarge'] = undefined;

/**
 * @member {String} screenSidewaysSuspensionType
 */
MachineFileFixed.prototype['screenSidewaysSuspensionType'] = undefined;

/**
 * @member {module:model/YesNo} isolationFrameMountedOnSuspensions
 */
MachineFileFixed.prototype['isolationFrameMountedOnSuspensions'] = undefined;

/**
 * @member {module:model/YesNo} isolationFrameCorrectlySpecified
 */
MachineFileFixed.prototype['isolationFrameCorrectlySpecified'] = undefined;

/**
 * @member {String} isolationFrameSuspensionType
 */
MachineFileFixed.prototype['isolationFrameSuspensionType'] = undefined;

/**
 * @member {module:model/FeedDischarge} isolationFrameFeedDischarge
 */
MachineFileFixed.prototype['isolationFrameFeedDischarge'] = undefined;

/**
 * @member {String} lubricationSpec
 */
MachineFileFixed.prototype['lubricationSpec'] = undefined;

/**
 * @member {String} greasingPowerUnit
 */
MachineFileFixed.prototype['greasingPowerUnit'] = undefined;

/**
 * @member {module:model/MachineFileFixedMotor} motor
 */
MachineFileFixed.prototype['motor'] = undefined;

/**
 * @member {String} gaDrawingNumberRevision
 */
MachineFileFixed.prototype['gaDrawingNumberRevision'] = undefined;

/**
 * @member {String} instructionManualNumberRevision
 */
MachineFileFixed.prototype['instructionManualNumberRevision'] = undefined;

/**
 * @member {String} sparePartBookNumberRevision
 */
MachineFileFixed.prototype['sparePartBookNumberRevision'] = undefined;





/**
 * Allowed values for the <code>isolationFramePresent</code> property.
 * @enum {String}
 * @readonly
 */
MachineFileFixed['IsolationFramePresentEnum'] = {

    /**
     * value: "Yes, installed"
     * @const
     */
    "Yes, installed": "Yes, installed",

    /**
     * value: "Yes, not used for FAT"
     * @const
     */
    "Yes, not used for FAT": "Yes, not used for FAT",

    /**
     * value: "No"
     * @const
     */
    "No": "No"
};



export default MachineFileFixed;

