<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <span>Motor</span>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="float-left">
          Motor frequency
        </div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.fixed.motor.motorFrequency" @input="emitChange" list="motorFrequencyListId"></b-form-input>
            <datalist id="motorFrequencyListId">
              <option v-for="type in motorFrequencyList" v-bind:key="type">{{ type }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.fixed.motor.motorFrequency }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Expected speed (RPM)
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.expectedSpeed" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.expectedSpeed }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Rotation direction
        </div>
        <div class="float-right">
          <b-form-group v-if="editable" class="mb-0">
            <b-form-select v-model="machineFile.fixed.motor.rotationDirection" :options="MachineFileFixedMotor.RotationDirectionEnum">
              <template #first>
                <b-form-select-option :value="null"></b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <span v-else>{{ machineFile.fixed.motor.rotationDirection }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          VSD/VSF installed?
        </div>
        <div class="float-right">
          <b-form-group @change="emitChange" v-if="editable">
            <b-form-radio-group
                id="radio-group-vsdvfd"
                v-model="machineFile.fixed.motor.vsdvfdInstalled"
                :options="radioOptions"
                name="radio-group-vsdvfd"
            ></b-form-radio-group>
          </b-form-group>
          <span v-else>{{ machineFile.fixed.motor.vsdvfdInstalled }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Electronic brake installed?
        </div>
        <div class="float-right">
          <b-form-group @change="emitChange" v-if="editable">
            <b-form-radio-group
                id="radio-group-electric-brake"
                v-model="machineFile.fixed.motor.electronicBrakeInstalled"
                :options="radioOptions"
                name="radio-group-electric-brake"
            ></b-form-radio-group>
          </b-form-group>
          <span v-else>{{ machineFile.fixed.motor.electronicBrakeInstalled }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Motor sheave diameter as specified in factory docs/GA?
        </div>
        <div class="float-right">
          <b-form-group @change="emitChange" v-if="editable">
            <b-form-radio-group
                id="radio-group-motor-sheave"
                v-model="machineFile.fixed.motor.motorSheaveDiameterAsSpecified"
                :options="radioOptions"
                name="radio-group-motor-sheave"
            ></b-form-radio-group>
          </b-form-group>
          <span v-else>{{ machineFile.fixed.motor.motorSheaveDiameterAsSpecified }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Driven sheave diameter as specified in factory docs/GA?
        </div>
        <div class="float-right">
          <b-form-group @change="emitChange" v-if="editable">
            <b-form-radio-group
                id="radio-group-driven-sheave"
                v-model="machineFile.fixed.motor.drivenSheaveDiameterAsSpecified"
                :options="radioOptions"
                name="radio-group-driven-sheave"
            ></b-form-radio-group>
          </b-form-group>
          <span v-else>{{ machineFile.fixed.motor.drivenSheaveDiameterAsSpecified }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="card m-auto">
          <div class="card-header d-flex align-items-center justify-content-between">
            <span>Motor A</span>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="float-left">
                Type
              </div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.motorA.type" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.motor.motorA.type }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">
                Manufacturer
              </div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.motorA.manufacturer" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.motor.motorA.manufacturer }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">
                Serial
              </div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.motorA.serial" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.motor.motorA.serial }}</span>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li class="list-group-item">
        <div class="card m-auto">
          <div class="card-header d-flex align-items-center justify-content-between">
            <span>Motor B</span>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="float-left">
                Type
              </div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.motorB.type" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.motor.motorB.type }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">
                Manufacturer
              </div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.motorB.manufacturer" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.motor.motorB.manufacturer }}</span>
              </div>
            </li>
            <li class="list-group-item">
              <div class="float-left">
                Serial
              </div>
              <div class="float-right">
                <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.motorB.serial" @input="emitChange"></b-form-input>
                <span v-else>{{ machineFile.fixed.motor.motorB.serial }}</span>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Exciter serial no.
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterSerials[0]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterSerials[0] }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Location
        </div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.fixed.motor.exciterLocations[0]" @input="emitChange" list="location0ListId"></b-form-input>
            <datalist id="location0ListId">
              <option v-for="type in locationList" v-bind:key="type">{{ type }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.fixed.motor.exciterLocations[0] }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Exciter serial no.
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterSerials[1]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterSerials[1] }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Location
        </div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.fixed.motor.exciterLocations[1]" @input="emitChange" list="location1ListId"></b-form-input>
            <datalist id="location1ListId">
              <option v-for="type in locationList" v-bind:key="type">{{ type }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.fixed.motor.exciterLocations[1] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter2Plus">
        <div class="float-left">
          Exciter serial no.
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterSerials[2]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterSerials[2] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter2Plus">
        <div class="float-left">
          Location
        </div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.fixed.motor.exciterLocations[2]" @input="emitChange" list="location2ListId"></b-form-input>
            <datalist id="location2ListId">
              <option v-for="type in locationList" v-bind:key="type">{{ type }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.fixed.motor.exciterLocations[2] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter3Plus">
        <div class="float-left">
          Exciter serial no.
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterSerials[3]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterSerials[3] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter3Plus">
        <div class="float-left">
          Location
        </div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.fixed.motor.exciterLocations[3]" @input="emitChange" list="location3ListId"></b-form-input>
            <datalist id="location3ListId">
              <option v-for="type in locationList" v-bind:key="type">{{ type }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.fixed.motor.exciterLocations[3] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter4Plus">
        <div class="float-left">
          Exciter serial no.
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterSerials[4]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterSerials[4] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter4Plus">
        <div class="float-left">
          Location
        </div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.fixed.motor.exciterLocations[4]" @input="emitChange" list="location4ListId"></b-form-input>
            <datalist id="location4ListId">
              <option v-for="type in locationList" v-bind:key="type">{{ type }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.fixed.motor.exciterLocations[4] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter4Plus">
        <div class="float-left">
          Exciter serial no.
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.motor.exciterSerials[5]" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.motor.exciterSerials[5] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowExciter4Plus">
        <div class="float-left">
          Location
        </div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.fixed.motor.exciterLocations[5]" @input="emitChange" list="location5ListId"></b-form-input>
            <datalist id="location5ListId">
              <option v-for="type in locationList" v-bind:key="type">{{ type }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.fixed.motor.exciterLocations[5] }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

import { MachineFileFixedMotor } from '@/screencheck_portal_api/src'

export default {
  name: 'MotorData',
  props: {
    data: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      machineFile: { ...this.data },
      radioOptions: [
        { text: 'yes', value: 'YES' },
        { text: 'no', value: 'NO' },
      ],
    }
  },
  methods: {
    emitChange () {
      this.$emit('onChange', this.machineFile)
    }
  },
  components: {},
  computed: {
    MachineFileFixedMotor () {
      return MachineFileFixedMotor
    },
    shouldShowExciter2Plus () {
      return this.machineFile.static.excitersAmount > 2
    },
    shouldShowExciter3Plus () {
      return this.machineFile.static.excitersAmount > 3
    },
    shouldShowExciter4Plus () {
      return this.machineFile.static.excitersAmount > 4
    },
    motorFrequencyList () {
      return [
        '50 Hz',
        '60 Hz',
      ]
    },
    locationList () {
      return [
        'TLT1',
        'TLB1',
        'TCT1',
        'TCB1',
        'TRT1',
        'TRB1',
        'ULU1',
        'ULB1',
        'UCU1',
        'UCB1',
        'URU1',
        'URB1',
        'CRE',
        'CRI',
        'CLE',
        'CLI'
      ]
    },
  }
}
</script>

<style lang="scss">
</style>
