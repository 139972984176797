<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <span>Revision</span>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="float-left">
          GA drawing code and revision
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.gaDrawingNumberRevision" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.gaDrawingNumberRevision }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Instruction manual reference
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.instructionManualNumberRevision" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.instructionManualNumberRevision }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Spare part book reference
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.sparePartBookNumberRevision" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.sparePartBookNumberRevision }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'RevisionData',
  props: {
    data: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      machineFile: { ...this.data }
    }
  },
  methods: {
    emitChange () {
      this.$emit('onChange', this.machineFile)
    }
  },
  components: {},
}
</script>

<style lang="scss">
</style>
