/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MachineFileFixedProcess model module.
 * @module model/MachineFileFixedProcess
 * @version 0.0.1
 */
class MachineFileFixedProcess {
    /**
     * Constructs a new <code>MachineFileFixedProcess</code>.
     * @alias module:model/MachineFileFixedProcess
     */
    constructor() { 
        
        MachineFileFixedProcess.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MachineFileFixedProcess</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MachineFileFixedProcess} obj Optional instance to populate.
     * @return {module:model/MachineFileFixedProcess} The populated <code>MachineFileFixedProcess</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MachineFileFixedProcess();

            if (data.hasOwnProperty('materialType')) {
                obj['materialType'] = ApiClient.convertToType(data['materialType'], 'String');
            }
            if (data.hasOwnProperty('dryWet')) {
                obj['dryWet'] = ApiClient.convertToType(data['dryWet'], 'String');
            }
            if (data.hasOwnProperty('topFeedSize')) {
                obj['topFeedSize'] = ApiClient.convertToType(data['topFeedSize'], 'Number');
            }
            if (data.hasOwnProperty('feedTonnage')) {
                obj['feedTonnage'] = ApiClient.convertToType(data['feedTonnage'], 'Number');
            }
            if (data.hasOwnProperty('dropHeightFeedMaterial')) {
                obj['dropHeightFeedMaterial'] = ApiClient.convertToType(data['dropHeightFeedMaterial'], 'Number');
            }
            if (data.hasOwnProperty('feedSizeMin')) {
                obj['feedSizeMin'] = ApiClient.convertToType(data['feedSizeMin'], 'Number');
            }
            if (data.hasOwnProperty('feedSizeMax')) {
                obj['feedSizeMax'] = ApiClient.convertToType(data['feedSizeMax'], 'Number');
            }
            if (data.hasOwnProperty('constant')) {
                obj['constant'] = ApiClient.convertToType(data['constant'], 'Number');
            }
            if (data.hasOwnProperty('surging')) {
                obj['surging'] = ApiClient.convertToType(data['surging'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MachineFileFixedProcess</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MachineFileFixedProcess</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['materialType'] && !(typeof data['materialType'] === 'string' || data['materialType'] instanceof String)) {
            throw new Error("Expected the field `materialType` to be a primitive type in the JSON string but got " + data['materialType']);
        }
        // ensure the json data is a string
        if (data['dryWet'] && !(typeof data['dryWet'] === 'string' || data['dryWet'] instanceof String)) {
            throw new Error("Expected the field `dryWet` to be a primitive type in the JSON string but got " + data['dryWet']);
        }

        return true;
    }


}



/**
 * @member {String} materialType
 */
MachineFileFixedProcess.prototype['materialType'] = undefined;

/**
 * @member {module:model/MachineFileFixedProcess.DryWetEnum} dryWet
 */
MachineFileFixedProcess.prototype['dryWet'] = undefined;

/**
 * @member {Number} topFeedSize
 */
MachineFileFixedProcess.prototype['topFeedSize'] = undefined;

/**
 * @member {Number} feedTonnage
 */
MachineFileFixedProcess.prototype['feedTonnage'] = undefined;

/**
 * @member {Number} dropHeightFeedMaterial
 */
MachineFileFixedProcess.prototype['dropHeightFeedMaterial'] = undefined;

/**
 * @member {Number} feedSizeMin
 */
MachineFileFixedProcess.prototype['feedSizeMin'] = undefined;

/**
 * @member {Number} feedSizeMax
 */
MachineFileFixedProcess.prototype['feedSizeMax'] = undefined;

/**
 * @member {Number} constant
 */
MachineFileFixedProcess.prototype['constant'] = undefined;

/**
 * @member {Number} surging
 */
MachineFileFixedProcess.prototype['surging'] = undefined;





/**
 * Allowed values for the <code>dryWet</code> property.
 * @enum {String}
 * @readonly
 */
MachineFileFixedProcess['DryWetEnum'] = {

    /**
     * value: "Dry"
     * @const
     */
    "DRY": "Dry",

    /**
     * value: "Wet"
     * @const
     */
    "WET": "Wet"
};



export default MachineFileFixedProcess;

