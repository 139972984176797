<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <span>Weight</span>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="float-left">
          Weight (kg)
        </div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.weight" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.weight }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">
          Weight calculation method
        </div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.fixed.weightMethod" @input="emitChange" list="weightCalcListId"></b-form-input>
            <datalist id="weightCalcListId">
              <option v-for="type in weightCalcList" v-bind:key="type">{{ type }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.fixed.weightMethod }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left" v-if="machineFile.static.shaftLinesAmount > 1">
          Counterweight / Exciter settings - Feed side
        </div>
        <div class="float-left" v-else>
          Counterweight / Exciter settings
        </div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.fixed.counterweights[0]" @input="emitChange" list="counterWeights0ListId"></b-form-input>
            <datalist id="counterWeights0ListId">
              <option v-for="type in counterWeightList" v-bind:key="type">{{ type }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.fixed.counterweights[0] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowCounterweight2">
        <div class="float-left" v-if="shouldShowCounterweight3">
          Counterweight / Exciter settings - Middle
        </div>
        <div class="float-left" v-else>
          Counterweight / Exciter settings - Discharge side
        </div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.fixed.counterweights[1]" @input="emitChange" list="counterWeights1ListId"></b-form-input>
            <datalist id="counterWeights1ListId">
              <option v-for="type in counterWeightList" v-bind:key="type">{{ type }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.fixed.counterweights[1] }}</span>
        </div>
      </li>
      <li class="list-group-item" v-if="shouldShowCounterweight3">
        <div class="float-left">
          Counterweight / Exciter settings - Discharge side
        </div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.fixed.counterweights[2]" @input="emitChange" list="counterWeights2ListId"></b-form-input>
            <datalist id="counterWeights2ListId">
              <option v-for="type in counterWeightList" v-bind:key="type">{{ type }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.fixed.counterweights[2] }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'WeightData',
  props: {
    data: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      machineFile: { ...this.data }
    }
  },
  methods: {
    emitChange () {
      this.$emit('onChange', this.machineFile)
    }
  },
  components: {},
  computed: {
    shouldShowCounterweight2 () {
      return this.machineFile.static.shaftLinesAmount > 1
    },
    shouldShowCounterweight3 () {
      return this.machineFile.static.shaftLinesAmount > 2
    },
    weightCalcList () {
      return [
        'Measure by crane',
        'Specification Sheet',
        'Name Plate',
        'Calculation',
        'Drawing',
        'Other'
      ]
    },
    counterWeightList () {
      switch (this.machineFile.static.mechanismType) {
        case 'ML':
          return ['100%', '98.2%', '87.6%', '76.8%', '69.7%', '60.4%', '55.1%', '49.2%', '42.8%']
        case 'ME':
          return []
        case 'MV':
          return ['-70mm', '-60 mm', '-50 mm', '-40 mm', '-30 mm', '-20 mm', '-10 mm', '0 mm', '10 mm', '20 mm', '30 mm', '40 mm', '50 mm', '60 mm', '70 mm']
        case 'MB':
          return []
        case 'V':
          return []
        case 'B':
          return ['10', 'X10', 'L10', 'LX10', '13', 'X13', 'L13', '16', 'XS16', 'X16', 'F16', 'FF16', '20', 'X20']
        case 'BLz / Unbalanced Motor':
          return ['70%', '75%', '80%', '85%', '90%', '95%', '100%']
        default:
          return []
      }
    }
  }
}
</script>

<style lang="scss">
</style>
