<template>
  <div>
    <div class="card">
      <div class="card-header d-flex align-items-center justify-content-between">
        <span>Media</span>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <div class="card m-auto">
            <div class="card-header d-flex align-items-center justify-content-between">
              <span>Top deck</span>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="float-left">
                  Media type
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.topDeck.type" @input="emitChange"
                                  list="topMediaTypeListId"></b-form-input>
                    <datalist id="topMediaTypeListId">
                      <option v-for="type in mediaTypeList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.topDeck.type }}</span>
                </div>
              </li>
              <li class="list-group-item" v-if="shouldShowTopDeckCompetitor">
                <div class="float-left">
                  Competitor name
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.topDeck.competitorName" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.topDeck.competitorName }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Media type specificity
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.topDeck.typeSpecificity" @input="emitChange"
                                  list="topMediaTypeSpecifyListId"></b-form-input>
                    <datalist id="topMediaTypeSpecifyListId">
                      <option v-for="type in mediaTopDeckTypeSpecifyList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.topDeck.typeSpecificity }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Material
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.topDeck.material" @input="emitChange"
                                  list="topMediaMaterialListId"></b-form-input>
                    <datalist id="topMediaMaterialListId">
                      <option v-for="type in mediaTopDeckMaterialList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.topDeck.material }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening shape
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.topDeck.openingShape" @input="emitChange"
                                  list="topMediaOpeningShapeListId"></b-form-input>
                    <datalist id="topMediaOpeningShapeListId">
                      <option v-for="type in mediaTopDeckOpeningShapeList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.topDeck.openingShape }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening size (mm)
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.topDeck.openingSize" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.topDeck.openingSize }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="list-group-item">
          <div class="card m-auto" v-if="shouldShowUpperMidDeck">
            <div class="card-header d-flex align-items-center justify-content-between">
              <span>Upper mid deck</span>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="float-left">
                  Media type
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.upperMidDeck.type" @input="emitChange"
                                  list="upperMidMediaTypeListId"></b-form-input>
                    <datalist id="upperMidMediaTypeListId">
                      <option v-for="type in mediaTypeList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.upperMidDeck.type }}</span>
                </div>
              </li>
              <li class="list-group-item" v-if="shouldShowUpperMidDeckCompetitor">
                <div class="float-left">
                  Competitor name
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.upperMidDeck.competitorName"
                                @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.upperMidDeck.competitorName }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Media type specificity
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.upperMidDeck.typeSpecificity" @input="emitChange"
                                  list="upperMidMediaTypeSpecifyListId"></b-form-input>
                    <datalist id="upperMidMediaTypeSpecifyListId">
                      <option v-for="type in mediaUpperMidDeckTypeSpecifyList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.upperMidDeck.typeSpecificity }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Material
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.upperMidDeck.material" @input="emitChange"
                                  list="upperMidMediaMaterialListId"></b-form-input>
                    <datalist id="upperMidMediaMaterialListId">
                      <option v-for="type in mediaUpperMidDeckMaterialList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.upperMidDeck.material }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening shape
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.upperMidDeck.openingShape" @input="emitChange"
                                  list="upperMidMediaOpeningShapeListId"></b-form-input>
                    <datalist id="upperMidMediaOpeningShapeListId">
                      <option v-for="type in mediaUpperMidDeckOpeningShapeList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.upperMidDeck.openingShape }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening size (mm)
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.upperMidDeck.openingSize"
                                @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.upperMidDeck.openingSize }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="list-group-item">
          <div class="card m-auto" v-if="shouldShowLowerMidDeck">
            <div class="card-header d-flex align-items-center justify-content-between">
              <span>Lower mid deck</span>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="float-left">
                  Media type
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.lowerMidDeck.type" @input="emitChange"
                                  list="lowerMidMediaTypeListId"></b-form-input>
                    <datalist id="lowerMidMediaTypeListId">
                      <option v-for="type in mediaTypeList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.lowerMidDeck.type }}</span>
                </div>
              </li>
              <li class="list-group-item" v-if="shouldShowLowerMidDeckCompetitor">
                <div class="float-left">
                  Competitor name
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.lowerMidDeck.competitorName"
                                @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.lowerMidDeck.competitorName }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Media type specificity
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.lowerMidDeck.typeSpecificity" @input="emitChange"
                                  list="lowerMidMediaTypeSpecifyListId"></b-form-input>
                    <datalist id="lowerMidMediaTypeSpecifyListId">
                      <option v-for="type in mediaLowerMidDeckTypeSpecifyList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.lowerMidDeck.typeSpecificity }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Material
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.lowerMidDeck.material" @input="emitChange"
                                  list="lowerMidMediaMaterialListId"></b-form-input>
                    <datalist id="lowerMidMediaMaterialListId">
                      <option v-for="type in mediaLowerMidDeckMaterialList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.lowerMidDeck.material }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening shape
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.lowerMidDeck.openingShape" @input="emitChange"
                                  list="lowerMidMediaOpeningShapeListId"></b-form-input>
                    <datalist id="lowerMidMediaOpeningShapeListId">
                      <option v-for="type in mediaLowerMidDeckOpeningShapeList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.lowerMidDeck.openingShape }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening size (mm)
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.lowerMidDeck.openingSize"
                                @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.lowerMidDeck.openingSize }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="list-group-item">
          <div class="card m-auto" v-if="shouldShowBottomDeck">
            <div class="card-header d-flex align-items-center justify-content-between">
              <span>Bottom deck</span>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="float-left">
                  Media type
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.bottomDeck.type" @input="emitChange"
                                  list="bottomMediaTypeListId"></b-form-input>
                    <datalist id="bottomMediaTypeListId">
                      <option v-for="type in mediaTypeList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.bottomDeck.type }}</span>
                </div>
              </li>
              <li class="list-group-item" v-if="shouldShowBottomDeckCompetitor">
                <div class="float-left">
                  Competitor name
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.bottomDeck.competitorName"
                                @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.bottomDeck.competitorName }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Media type specificity
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.bottomDeck.typeSpecificity" @input="emitChange"
                                  list="bottomMediaTypeSpecifyListId"></b-form-input>
                    <datalist id="bottomMediaTypeSpecifyListId">
                      <option v-for="type in mediaBottomDeckTypeSpecifyList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.bottomDeck.typeSpecificity }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Material
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.bottomDeck.material" @input="emitChange"
                                  list="bottomMediaMaterialListId"></b-form-input>
                    <datalist id="bottomMediaMaterialListId">
                      <option v-for="type in mediaBottomDeckMaterialList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.bottomDeck.material }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening shape
                </div>
                <div class="float-right">
                  <div v-if="editable">
                    <b-form-input class="text-right" v-model="machineFile.fixed.media.bottomDeck.openingShape" @input="emitChange"
                                  list="bottomMediaOpeningShapeListId"></b-form-input>
                    <datalist id="bottomMediaOpeningShapeListId">
                      <option v-for="type in mediaBottomDeckOpeningShapeList" v-bind:key="type">{{ type }}</option>
                    </datalist>
                  </div>
                  <span v-else>{{ machineFile.fixed.media.bottomDeck.openingShape }}</span>
                </div>
              </li>
              <li class="list-group-item">
                <div class="float-left">
                  Opening size (mm)
                </div>
                <div class="float-right">
                  <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.media.bottomDeck.openingSize" @input="emitChange"></b-form-input>
                  <span v-else>{{ machineFile.fixed.media.bottomDeck.openingSize }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MediaData',
  props: {
    data: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      machineFile: { ...this.data }
    }
  },
  methods: {
    emitChange () {
      this.$emit('onChange', this.machineFile)
    },
    mediaTypeSpecificList (mediaType) {
      switch (mediaType) {
        case 'Primary':
          return [
            'Grizzly bars',
            'Perforated steel plate',
            'MP Panel',
            'PCO Panel'
          ]
        case 'Modular':
          return [
            'LS',
            'GS',
            'TS',
            'PS'
          ]
        case 'Tension':
          return [
            'TCO',
            'TFX',
            'PCL',
            'CLS',
            'UFS'
          ]
        case 'Competitor':
          return [
            'Primary',
            'Modular',
            'Tension'
          ]
        default:
          return []
      }
    },
    mediaMaterialList (mediaType, mediaTypeSpecificity) {
      switch (mediaType) {
        case 'Primary':
          switch (mediaTypeSpecificity) {
            case 'Grizzly bars':
              return [
                'Steel',
                'RU-SKEGGA'
              ]
            case 'Perforated steel plate':
              return [
                'Steel'
              ]
            case 'MP Panel':
              return [
                'RU'
              ]
            case 'PCO Panel':
              return [
                'RU'
              ]
            default:
              return []
          }
        case 'Modular':
          return [
            'RU',
            'PU'
          ]
        case 'Tension':
          switch (mediaTypeSpecificity) {
            case 'TCO':
              return [
                'RU',
                'PU'
              ]
            case 'TFX':
              return [
                'RU'
              ]
            case 'PCL':
              return [
                'Wiremesh'
              ]
            case 'CLS':
              return [
                'Wiremesh'
              ]
            case 'UFS':
              return [
                'PU'
              ]
            default:
              return []

          }
        case 'Competitor':
          return []
        default:
          return []
      }
    },
    mediaOpeningShapeList (mediaType, mediaTypeSpecificity, mediaMaterial) {
      switch (mediaType) {
        case 'Primary':
          switch (mediaTypeSpecificity) {
            case 'Grizzly bars':
              switch (mediaMaterial) {
                case 'Steel':
                  return [
                    'Bars',
                    'Cassettes'
                  ]
                case 'RU-SKEGGA':
                  return []
                default:
                  return []
              }
            case 'Perforated steel plate':
              return [
                'Round'
              ]
            case 'MP Panel':
              return [
                'Round',
                'Square',
                'SLS',
                'STS'
              ]
            case 'PCO Panel':
              return [
                'Round',
                'Square',
                'SLS',
                'STS'
              ]
            default:
              return []
          }
        case 'Modular':
          return [
            'Round',
            'Square',
            'SLS',
            'STS'
          ]
        case 'Tension':
          if (mediaTypeSpecificity === null || mediaMaterial === null) {
            return []
          }
          if (mediaTypeSpecificity === 'UFS' && mediaMaterial === 'PU') {
            return [
              'STS'
            ]
          } else {
            return [
              'Round',
              'Square',
              'SLS',
              'STS'
            ]
          }

        case 'Competitor':
          return []
        default:
          return []
      }
    }
  },
  components: {},
  computed: {
    shouldShowTopDeckCompetitor () {
      return this.machineFile.fixed.media.topDeck.type?.toLowerCase() === 'competitor'
    },
    shouldShowUpperMidDeckCompetitor () {
      return this.machineFile.fixed.media.upperMidDeck.type?.toLowerCase() === 'competitor'
    },
    shouldShowLowerMidDeckCompetitor () {
      return this.machineFile.fixed.media.lowerMidDeck.type?.toLowerCase() === 'competitor'
    },
    shouldShowBottomDeckCompetitor () {
      return this.machineFile.fixed.media.bottomDeck.type?.toLowerCase() === 'competitor'
    },
    shouldShowUpperMidDeck () {
      return this.machineFile.static.decksAmount > 2
    },
    shouldShowLowerMidDeck () {
      return this.machineFile.static.decksAmount > 3
    },
    shouldShowBottomDeck () {
      return this.machineFile.static.decksAmount > 1
    },
    mediaTypeList () {
      return [
        'Primary',
        'Modular',
        'Tension',
        'Competitor'
      ]
    },
    mediaTopDeckTypeSpecifyList () {
      return this.mediaTypeSpecificList(this.machineFile.fixed.media.topDeck.type)
    },
    mediaLowerMidDeckTypeSpecifyList () {
      return this.mediaTypeSpecificList(this.machineFile.fixed.media.lowerMidDeck.type)
    },
    mediaUpperMidDeckTypeSpecifyList () {
      return this.mediaTypeSpecificList(this.machineFile.fixed.media.upperMidDeck.type)
    },
    mediaBottomDeckTypeSpecifyList () {
      return this.mediaTypeSpecificList(this.machineFile.fixed.media.bottomDeck.type)
    },
    mediaTopDeckMaterialList () {
      return this.mediaMaterialList(this.machineFile.fixed.media.topDeck.type, this.machineFile.fixed.media.topDeck.typeSpecificity)
    },
    mediaLowerMidDeckMaterialList () {
      return this.mediaMaterialList(this.machineFile.fixed.media.lowerMidDeck.type, this.machineFile.fixed.media.lowerMidDeck.typeSpecificity)
    },
    mediaUpperMidDeckMaterialList () {
      return this.mediaMaterialList(this.machineFile.fixed.media.upperMidDeck.type, this.machineFile.fixed.media.upperMidDeck.typeSpecificity)
    },
    mediaBottomDeckMaterialList () {
      return this.mediaMaterialList(this.machineFile.fixed.media.bottomDeck.type, this.machineFile.fixed.media.bottomDeck.typeSpecificity)
    },
    mediaTopDeckOpeningShapeList () {
      return this.mediaOpeningShapeList(this.machineFile.fixed.media.topDeck.type, this.machineFile.fixed.media.topDeck.typeSpecificity, this.machineFile.fixed.media.topDeck.material)
    },
    mediaLowerMidDeckOpeningShapeList () {
      return this.mediaOpeningShapeList(this.machineFile.fixed.media.lowerMidDeck.type, this.machineFile.fixed.media.lowerMidDeck.typeSpecificity, this.machineFile.fixed.media.lowerMidDeck.material)
    },
    mediaUpperMidDeckOpeningShapeList () {
      return this.mediaOpeningShapeList(this.machineFile.fixed.media.upperMidDeck.type, this.machineFile.fixed.media.upperMidDeck.typeSpecificity, this.machineFile.fixed.media.upperMidDeck.material)
    },
    mediaBottomDeckOpeningShapeList () {
      return this.mediaOpeningShapeList(this.machineFile.fixed.media.bottomDeck.type, this.machineFile.fixed.media.bottomDeck.typeSpecificity, this.machineFile.fixed.media.bottomDeck.material)
    }
  }
}
</script>

<style lang="scss">
</style>
