/**
 * ScreenCheck Portal API
 * ScreenCheck Portal REST API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MachineFileStatic model module.
 * @module model/MachineFileStatic
 * @version 0.0.1
 */
class MachineFileStatic {
    /**
     * Constructs a new <code>MachineFileStatic</code>.
     * @alias module:model/MachineFileStatic
     */
    constructor() { 
        
        MachineFileStatic.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MachineFileStatic</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MachineFileStatic} obj Optional instance to populate.
     * @return {module:model/MachineFileStatic} The populated <code>MachineFileStatic</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MachineFileStatic();

            if (data.hasOwnProperty('machineSerialNumber')) {
                obj['machineSerialNumber'] = ApiClient.convertToType(data['machineSerialNumber'], 'String');
            }
            if (data.hasOwnProperty('machineType')) {
                obj['machineType'] = ApiClient.convertToType(data['machineType'], 'String');
            }
            if (data.hasOwnProperty('dealerName')) {
                obj['dealerName'] = ApiClient.convertToType(data['dealerName'], 'String');
            }
            if (data.hasOwnProperty('customerEquipmentCode')) {
                obj['customerEquipmentCode'] = ApiClient.convertToType(data['customerEquipmentCode'], 'String');
            }
            if (data.hasOwnProperty('customerName')) {
                obj['customerName'] = ApiClient.convertToType(data['customerName'], 'String');
            }
            if (data.hasOwnProperty('siteName')) {
                obj['siteName'] = ApiClient.convertToType(data['siteName'], 'String');
            }
            if (data.hasOwnProperty('application')) {
                obj['application'] = ApiClient.convertToType(data['application'], 'String');
            }
            if (data.hasOwnProperty('decksAmount')) {
                obj['decksAmount'] = ApiClient.convertToType(data['decksAmount'], 'Number');
            }
            if (data.hasOwnProperty('driveType')) {
                obj['driveType'] = ApiClient.convertToType(data['driveType'], 'String');
            }
            if (data.hasOwnProperty('drivePosition')) {
                obj['drivePosition'] = ApiClient.convertToType(data['drivePosition'], 'String');
            }
            if (data.hasOwnProperty('shaftLinesAmount')) {
                obj['shaftLinesAmount'] = ApiClient.convertToType(data['shaftLinesAmount'], 'Number');
            }
            if (data.hasOwnProperty('excitersAmount')) {
                obj['excitersAmount'] = ApiClient.convertToType(data['excitersAmount'], 'Number');
            }
            if (data.hasOwnProperty('mechanismType')) {
                obj['mechanismType'] = ApiClient.convertToType(data['mechanismType'], 'String');
            }
            if (data.hasOwnProperty('exciterTypes')) {
                obj['exciterTypes'] = ApiClient.convertToType(data['exciterTypes'], ['String']);
            }
            if (data.hasOwnProperty('lubrication')) {
                obj['lubrication'] = ApiClient.convertToType(data['lubrication'], 'String');
            }
            if (data.hasOwnProperty('motionType')) {
                obj['motionType'] = ApiClient.convertToType(data['motionType'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MachineFileStatic</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MachineFileStatic</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['machineSerialNumber'] && !(typeof data['machineSerialNumber'] === 'string' || data['machineSerialNumber'] instanceof String)) {
            throw new Error("Expected the field `machineSerialNumber` to be a primitive type in the JSON string but got " + data['machineSerialNumber']);
        }
        // ensure the json data is a string
        if (data['machineType'] && !(typeof data['machineType'] === 'string' || data['machineType'] instanceof String)) {
            throw new Error("Expected the field `machineType` to be a primitive type in the JSON string but got " + data['machineType']);
        }
        // ensure the json data is a string
        if (data['dealerName'] && !(typeof data['dealerName'] === 'string' || data['dealerName'] instanceof String)) {
            throw new Error("Expected the field `dealerName` to be a primitive type in the JSON string but got " + data['dealerName']);
        }
        // ensure the json data is a string
        if (data['customerEquipmentCode'] && !(typeof data['customerEquipmentCode'] === 'string' || data['customerEquipmentCode'] instanceof String)) {
            throw new Error("Expected the field `customerEquipmentCode` to be a primitive type in the JSON string but got " + data['customerEquipmentCode']);
        }
        // ensure the json data is a string
        if (data['customerName'] && !(typeof data['customerName'] === 'string' || data['customerName'] instanceof String)) {
            throw new Error("Expected the field `customerName` to be a primitive type in the JSON string but got " + data['customerName']);
        }
        // ensure the json data is a string
        if (data['siteName'] && !(typeof data['siteName'] === 'string' || data['siteName'] instanceof String)) {
            throw new Error("Expected the field `siteName` to be a primitive type in the JSON string but got " + data['siteName']);
        }
        // ensure the json data is a string
        if (data['application'] && !(typeof data['application'] === 'string' || data['application'] instanceof String)) {
            throw new Error("Expected the field `application` to be a primitive type in the JSON string but got " + data['application']);
        }
        // ensure the json data is a string
        if (data['driveType'] && !(typeof data['driveType'] === 'string' || data['driveType'] instanceof String)) {
            throw new Error("Expected the field `driveType` to be a primitive type in the JSON string but got " + data['driveType']);
        }
        // ensure the json data is a string
        if (data['drivePosition'] && !(typeof data['drivePosition'] === 'string' || data['drivePosition'] instanceof String)) {
            throw new Error("Expected the field `drivePosition` to be a primitive type in the JSON string but got " + data['drivePosition']);
        }
        // ensure the json data is a string
        if (data['mechanismType'] && !(typeof data['mechanismType'] === 'string' || data['mechanismType'] instanceof String)) {
            throw new Error("Expected the field `mechanismType` to be a primitive type in the JSON string but got " + data['mechanismType']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['exciterTypes'])) {
            throw new Error("Expected the field `exciterTypes` to be an array in the JSON data but got " + data['exciterTypes']);
        }
        // ensure the json data is a string
        if (data['lubrication'] && !(typeof data['lubrication'] === 'string' || data['lubrication'] instanceof String)) {
            throw new Error("Expected the field `lubrication` to be a primitive type in the JSON string but got " + data['lubrication']);
        }
        // ensure the json data is a string
        if (data['motionType'] && !(typeof data['motionType'] === 'string' || data['motionType'] instanceof String)) {
            throw new Error("Expected the field `motionType` to be a primitive type in the JSON string but got " + data['motionType']);
        }

        return true;
    }


}



/**
 * @member {String} machineSerialNumber
 */
MachineFileStatic.prototype['machineSerialNumber'] = undefined;

/**
 * @member {String} machineType
 */
MachineFileStatic.prototype['machineType'] = undefined;

/**
 * @member {String} dealerName
 */
MachineFileStatic.prototype['dealerName'] = undefined;

/**
 * @member {String} customerEquipmentCode
 */
MachineFileStatic.prototype['customerEquipmentCode'] = undefined;

/**
 * @member {String} customerName
 */
MachineFileStatic.prototype['customerName'] = undefined;

/**
 * @member {String} siteName
 */
MachineFileStatic.prototype['siteName'] = undefined;

/**
 * @member {String} application
 */
MachineFileStatic.prototype['application'] = undefined;

/**
 * @member {module:model/MachineFileStatic.DecksAmountEnum} decksAmount
 */
MachineFileStatic.prototype['decksAmount'] = undefined;

/**
 * @member {String} driveType
 */
MachineFileStatic.prototype['driveType'] = undefined;

/**
 * @member {module:model/MachineFileStatic.DrivePositionEnum} drivePosition
 */
MachineFileStatic.prototype['drivePosition'] = undefined;

/**
 * @member {module:model/MachineFileStatic.ShaftLinesAmountEnum} shaftLinesAmount
 */
MachineFileStatic.prototype['shaftLinesAmount'] = undefined;

/**
 * @member {module:model/MachineFileStatic.ExcitersAmountEnum} excitersAmount
 */
MachineFileStatic.prototype['excitersAmount'] = undefined;

/**
 * @member {String} mechanismType
 */
MachineFileStatic.prototype['mechanismType'] = undefined;

/**
 * @member {Array.<String>} exciterTypes
 */
MachineFileStatic.prototype['exciterTypes'] = undefined;

/**
 * @member {module:model/MachineFileStatic.LubricationEnum} lubrication
 */
MachineFileStatic.prototype['lubrication'] = undefined;

/**
 * @member {module:model/MachineFileStatic.MotionTypeEnum} motionType
 */
MachineFileStatic.prototype['motionType'] = undefined;





/**
 * Allowed values for the <code>decksAmount</code> property.
 * @enum {Number}
 * @readonly
 */
MachineFileStatic['DecksAmountEnum'] = {

    /**
     * value: 1
     * @const
     */
    "1": 1,

    /**
     * value: 2
     * @const
     */
    "2": 2,

    /**
     * value: 3
     * @const
     */
    "3": 3,

    /**
     * value: 4
     * @const
     */
    "4": 4
};


/**
 * Allowed values for the <code>drivePosition</code> property.
 * @enum {String}
 * @readonly
 */
MachineFileStatic['DrivePositionEnum'] = {

    /**
     * value: "Left"
     * @const
     */
    "LEFT": "Left",

    /**
     * value: "Center"
     * @const
     */
    "CENTER": "Center",

    /**
     * value: "Right"
     * @const
     */
    "RIGHT": "Right"
};


/**
 * Allowed values for the <code>shaftLinesAmount</code> property.
 * @enum {Number}
 * @readonly
 */
MachineFileStatic['ShaftLinesAmountEnum'] = {

    /**
     * value: 1
     * @const
     */
    "1": 1,

    /**
     * value: 2
     * @const
     */
    "2": 2,

    /**
     * value: 3
     * @const
     */
    "3": 3
};


/**
 * Allowed values for the <code>excitersAmount</code> property.
 * @enum {Number}
 * @readonly
 */
MachineFileStatic['ExcitersAmountEnum'] = {

    /**
     * value: 2
     * @const
     */
    "2": 2,

    /**
     * value: 3
     * @const
     */
    "3": 3,

    /**
     * value: 4
     * @const
     */
    "4": 4,

    /**
     * value: 6
     * @const
     */
    "6": 6
};


/**
 * Allowed values for the <code>lubrication</code> property.
 * @enum {String}
 * @readonly
 */
MachineFileStatic['LubricationEnum'] = {

    /**
     * value: "Grease"
     * @const
     */
    "GREASE": "Grease",

    /**
     * value: "Oil"
     * @const
     */
    "OIL": "Oil"
};


/**
 * Allowed values for the <code>motionType</code> property.
 * @enum {String}
 * @readonly
 */
MachineFileStatic['MotionTypeEnum'] = {

    /**
     * value: "AGG elliptical"
     * @const
     */
    "AGG_ELLIPTICAL": "AGG elliptical",

    /**
     * value: "AGG circular"
     * @const
     */
    "AGG_CIRCULAR": "AGG circular",

    /**
     * value: "AGG linear"
     * @const
     */
    "AGG_LINEAR": "AGG linear",

    /**
     * value: "MNG elliptical"
     * @const
     */
    "MNG_ELLIPTICAL": "MNG elliptical",

    /**
     * value: "MNG circular"
     * @const
     */
    "MNG_CIRCULAR": "MNG circular",

    /**
     * value: "MNG linear"
     * @const
     */
    "MNG_LINEAR": "MNG linear"
};



export default MachineFileStatic;

