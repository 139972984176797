<template>
  <div class="card">
    <div class="card-header d-flex align-items-center justify-content-between">
      <span>Lubrication</span>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="float-left">Lubrication specification</div>
        <div class="float-right">
          <b-form-input v-if="editable" class="text-right" v-model="machineFile.fixed.lubricationSpec" @input="emitChange"></b-form-input>
          <span v-else>{{ machineFile.fixed.lubricationSpec }}</span>
        </div>
      </li>
      <li class="list-group-item">
        <div class="float-left">Greasing power unit setting</div>
        <div class="float-right">
          <div v-if="editable">
            <b-form-input class="text-right" v-model="machineFile.fixed.greasingPowerUnit" @input="emitChange" list="greasingPowerListId"></b-form-input>
            <datalist id="greasingPowerListId">
              <option v-for="type in greasingPowerList" v-bind:key="type">{{ type }}</option>
            </datalist>
          </div>
          <span v-else>{{ machineFile.fixed.greasingPowerUnit }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'LubricationData',
  props: {
    data: {
      type: Object
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      machineFile: { ...this.data }
    }
  },
  methods: {
    emitChange () {
      this.$emit('onChange', this.machineFile)
    }
  },
  components: {},
  computed: {
    greasingPowerList () {
      return [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
      ]
    },
  },
}
</script>

<style lang="scss">
</style>
